import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";

export const StcFetch = () => {
  const [sensorData, setSensorData] = useState(null);
  useEffect(() => {
    fetch(
      "https://tnmooyflntrajxp34ymztb6rq40qrmlr.lambda-url.eu-west-2.on.aws/"
    )
      .then((res) => res.json())
      .then((data) => setSensorData(data))
      .then(() => {
        var date = new Date(sensorData?.LastUpdate);
        console.log(date.toDateString());
      });
  }, []);

  const chart1 = {
    xAxis: {
      type: "time",
    },
    yAxis: [
      {
        name: "Pressure",
        type: "value",
        alignTicks: true,
      },
      {
        name: "Flow",
        type: "value",
        alignTicks: true,
        // splitLine: {
        //   show: false,
        // },
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: function (params) {
        var result = "";
        if (params.length < 3) {
          params.forEach(function (item) {
            result +=
              item.seriesName + ": " + item.value[1].toFixed(2) + "<br>";
          });
        } else {
          result =
            "<strong>" +
            new Date(params[0].value[0]).toLocaleTimeString() +
            '</strong><br><strong style="color: ' +
            params[0].color +
            ';">Inlet:</strong> ' +
            params[0].value[1].toFixed(2) +
            ' bar<br><strong style="color: ' +
            params[1].color +
            ';">House:</strong> ' +
            params[1].value[1].toFixed(2) +
            ' bar<br><strong style="color: ' +
            params[2].color +
            ';">Flow:</strong> ' +
            params[2].value[1].toFixed(2) +
            " l/min";
        }
        return result;
      },
      // valueFormatter: (value) => value.toFixed(2),
    },
    dataZoom: [
      {
        type: "slider",
      },
      {
        type: "inside",
      },
    ],
    series: [
      {
        name: "Pressure 1",
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.pressure_1,
        ]),
        type: "line",
        symbol: "none",
        yAxisIndex: 0,
      },
      {
        name: "Pressure 2",
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.pressure_2,
        ]),
        type: "line",
        symbol: "none",
        yAxisIndex: 0,
      },
      {
        name: "Flow",
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.flow,
        ]),
        type: "line",
        symbol: "none",
        areaStyle: {
          opacity: 0.5,
        },
        yAxisIndex: 1,
      },
    ],
  };

  const chart2 = {
    xAxis: {
      name: "Flow",
      type: "value",
    },
    yAxis: {
      name: "Pressure drop",
      type: "value",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    series: [
      {
        data: sensorData?.Readings.filter(
          (reading) => reading.flow > 0.001
        ).map((reading) => [
          reading.flow,
          reading.pressure_1 - reading.pressure_2,
        ]),
        type: "scatter",
        symbolSize: 10,
        yAxisIndex: 0,
      },
    ],
  };

  const chart3 = {
    xAxis: {
      type: "time",
    },
    yAxis: {
      name: "Temperature",
      type: "value",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    series: [
      {
        data: sensorData?.Readings.map((reading) => [
          reading.timestamp,
          reading.temperature,
        ]),
        type: "line",
        symbol: "none",
        yAxisIndex: 0,
      },
    ],
  };

  return (
    <div style={{ background: "white", padding: 5, minWidth: "80vw" }}>
      {sensorData ? (
        <>
          <h2 style={{ color: "black" }}>
            Inlet:&nbsp;&nbsp;
            {sensorData.Readings.slice(-1)[0].pressure_1.toFixed(2)} bar
            <br />
            House:&nbsp;&nbsp;
            {sensorData.Readings.slice(-1)[0].pressure_2.toFixed(2)} bar
          </h2>
          <p style={{ color: "black", whiteSpace: "" }}>
            {new Date(sensorData?.LastUpdate).toDateString()}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {new Date(sensorData?.LastUpdate).toLocaleTimeString()}
          </p>
        </>
      ) : (
        "loading..."
      )}

      <ReactEcharts option={chart1} style={{ height: 400 }} />
      <br />
      <ReactEcharts option={chart2} style={{ height: 400 }} />
      <br />
      <ReactEcharts option={chart3} style={{ height: 400 }} />
      {/* <pre>{JSON.stringify(sensorData?.Readings, null, 2)}</pre> */}
    </div>
  );
};
